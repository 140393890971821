import DossierList from './DossierList';
import DossierShow from './DossierShow';
import DescriptionIcon from '@material-ui/icons/Description';

const dossiers = {
  list: DossierList,
  show: DossierShow,
  icon: DescriptionIcon,
};

export default dossiers;