// in src/App.js
import {React} from 'react';
// import { Admin } from 'react-admin';EditGuesser
import { Admin,
    Resource,
    fetchUtils,
} from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from './i18n/en';
import URL from './backend';
import {themeReducer, tokenReducer} from './themeReducer';
import { Login, AppLayout } from './layout';
import customRoutes from './routes';

import Dashboard from './dashboard/Dashboard';
import AuthProvider from './authProvider';

import users from './clients';
import internalusers from './utilisateurs';
import invoices from './reservations';
import recus from './recus';
import dossiers from './dossiers';
import fonds from './fonds';
import photos from './photos';
import projets from './projets';
import tickets from './tickets';
import documents from './documents';


const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(`${URL}/index.php`, httpClient);

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }
    // Always fallback on english
    return englishMessages;
}, 'en');

const App = () => (
    <Admin 
        title=""
        dataProvider={dataProvider}
        customReducers={{ theme: themeReducer, token: tokenReducer }}
        customRoutes={customRoutes}
        authProvider={AuthProvider}
        dashboard={Dashboard}
        loginPage={Login}
        layout={AppLayout}
        i18nProvider={i18nProvider}
        disableTelemetry
        >
        <Resource name="clients" {...users} />
        <Resource name="utilisateurs" {...internalusers} />
        <Resource name="reservations" {...invoices} />
        <Resource name="recus" {...recus} />
        <Resource name="dossiers" {...dossiers} />
        <Resource name="fonds" {...fonds} />
        <Resource name="photos" {...photos} />
        <Resource name="projets" {...projets} />
        <Resource name="tickets" {...tickets} />
        <Resource name="documents" {...documents} />
    </Admin>
);

export default App;
