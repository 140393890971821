import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useShowController } from 'react-admin';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Card
  } from '@material-ui/core';
import ImagePreviewButton from '../ImagePreviewButton'


const DocTable = ({ dossier }) => {
  const classes = useStyles();

    if (! Array.isArray(dossier.documents)) return null;
    return (
      <Card className={classes.root}>
        { dossier.documents.length > 0 ? 
          <Table>
            <TableBody>
              {dossier.documents.map((doc) => {
                return doc.label && doc.label.length > 0 ? 
                <TableRow key={doc.id}>
                  <TableCell>{doc.label}</TableCell>
                  <TableCell>{doc.rate}</TableCell>
                  <TableCell align="right">
                    { doc.images_count > 0 ?
                    <ImagePreviewButton record={doc} /> : null
                    }
                  </TableCell>
                </TableRow> : null;
                }
              )}
            </TableBody>
          </Table>
          : 
          <Typography variant="span" gutterBottom>
          Pas de photos !
          </Typography>
        }
      </Card>
    );
  };

const PhotoShow = (props) => {
    const { record } = useShowController(props);

    if (!record) return null;
    return (
    <DocTable dossier={record} />
    );
};

const useStyles = makeStyles({
  root: { width: '75%', margin: 'auto', padding: '15px' },
  spacer: { height: 20 },
});

export default PhotoShow;