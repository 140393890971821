import RecuList from './RecuList';
import RecuShow from './RecuShow';
import ReceiptIcon from '@material-ui/icons/Receipt';

const recus = {
  list: RecuList,
  show: RecuShow,
  icon: ReceiptIcon,
};

export default recus;