import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    List,
    Datagrid,
    TextField,
    EmailField,
} from 'react-admin';

import PermissionsField from './PermissionsField';

const rowStyle = (record) => ({
    color: record.is_user === true ? 'green' : 'white',
});

const useStyles = makeStyles(theme => ({
  nb_commands: { color: 'purple' },
  hiddenOnSmallScreens: {
      display: 'table-cell',
      [theme.breakpoints.down('md')]: {
          display: 'none',
      },
  },
}));

const InternalUserList = (props) => {
  const classes = useStyles();
  return (
    <List {...props} bulkActionButtons={false} exporter={false}>
      <Datagrid rowStyle={rowStyle}>
        <TextField source="name" label="Nom" sortable={false} />
        <EmailField source="email" label="Email"  sortable={false}/>
        <PermissionsField source="permission" label="Permissions"  sortable={false} cellClassName={classes.hiddenOnSmallScreens}
                headerClassName={classes.hiddenOnSmallScreens}/>
      </Datagrid>
    </List>
  );
}

export default InternalUserList;