import * as React from 'react';
import {
    Edit,
    SaveButton,
    TextInput,
    PasswordInput,
    Toolbar,
    FormWithRedirect,
    required,
    useNotify,
    useRefresh,
    useRedirect,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';

export const validatePasswords = ({ password, confirm_password,}) => {
  const errors = {};
  if (password && confirm_password && password !== confirm_password) {
      errors.confirm_password = [
          "Les mots de passe ne sont pas identiques",
      ];
  }
  return errors;
};

const validateUserEdit = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = ["Veuillez d'abord définir l'email du client au niveau du SI"];
  }
  if (values.password && values.confirm_password && values.password !== values.confirm_password) {
    errors.confirm_password = [
      "Les mots de passe ne sont pas identiques",
    ];
  }
  return errors
};

export const UserTitle = ({ record }) => (
  <>
    <Typography variant="h6" gutterBottom>Modifier le mot de passe utilisateur du client: {record.name}</Typography>
  </>
);

const UserEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    notify(`Mot de passe modifié pour "${data.name}"`)
    redirect('/clients');
    refresh();
  };
  const onFailure = (error) => {
    notify(`Le mot de passe n'a pas pu ête modifié: ${error.message}`)
    redirect('/clients');
    refresh();
  };
  return (
    <Edit onSuccess={onSuccess} onFailure={onFailure} title={<UserTitle />} component="div" {...props} mutationMode="pessimistic">
      <UserForm {...props} />
    </Edit>
  );
}

const UserForm = (props) => {
  return (
    <FormWithRedirect
      {...props}
      validate={validateUserEdit}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                  <Typography variant="h6" gutterBottom>
                      Informations du client
                  </Typography>
                  <TextInput
                    label="ID"
                    source="id"
                    resource="clients"
                    fullWidth
                    disabled="disabled"
                  />
                  <TextInput
                    label="Nom du client"
                    source="name"
                    resource="clients"
                    validate={requiredValidate}
                    fullWidth
                    disabled="disabled"
                  />
                  <TextInput
                    label="Téléphone"
                    type="text"
                    source="phone"
                    resource="clients"
                    fullWidth
                    disabled="disabled"
                  />
                  { formProps.user_id !== null?
                  <TextInput
                    label="Email"
                    type="email"
                    source="email"
                    resource="clients"
                    fullWidth
                    disabled="disabled"
                  /> : 
                  <TextInput
                   label="Email"
                    type="email"
                    source="email"
                    resource="clients"
                    fullWidth
                  />
                  }
                  <Box mt="1em" />
                  <Typography variant="h6" gutterBottom>
                    Changer le mot de passe
                  </Typography>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <PasswordInput
                        label="Mot de passe"
                        source="password"
                        resource="clients"
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <PasswordInput
                        label="Confirmez le mot de passe"
                        source="confirm_password"
                        resource="clients"
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="clients"
              >
              </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

const requiredValidate = [required()];

export default UserEdit;