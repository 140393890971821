import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
    useShowController,
} from 'react-admin';

import Basket from './Basket';

const InvoiceShow = (props) => {
    const { record } = useShowController(props);
    const classes = useStyles();

    if (!record) return null;
    return (
        <Card className={classes.root}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="h6" gutterBottom>
                            {record.program}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6" gutterBottom align="right">
                            FACTURE: {record.ref}
                        </Typography>
                    </Grid>
                </Grid>
                <div className={classes.spacer}>&nbsp;</div>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography variant="h6" gutterBottom align="center">
                          Date
                        </Typography>
                        <Typography gutterBottom align="center">
                          {new Intl.DateTimeFormat('fr-FR').format(new Date(record.date))}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6" gutterBottom align="center">
                          Montant
                        </Typography>
                        <Typography gutterBottom align="center">
                          {new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'XOF'}).format(record.amount)}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6" gutterBottom align="center">
                            Payé
                        </Typography>
                        <Typography gutterBottom align="center">
                          {new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'XOF'}).format(record.paid_amount)}
                        </Typography>
                    </Grid>
                </Grid>
                <div className={classes.invoices}>
                    <Basket invoice={record} />
                </div>
            </CardContent>
        </Card>
    );
};

export default InvoiceShow;

const useStyles = makeStyles({
    root: { width: '75%', margin: 'auto'},
    spacer: { height: 20 },
    invoices: { margin: '10px 0' },
});