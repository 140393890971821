import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card
} from '@material-ui/core';
import { useShowController } from 'react-admin';
import DocQuickPreviewButton from '../DocPreviewButton';

const DocTable = ({ invoice }) => {
  const classes = useStyles();
  if (! Array.isArray(invoice.documents)) return null;

  return (
    <Card className={classes.root}>
      {/* <Typography variant="h5" gutterBottom>
      Liste des documents associés à cette facture
      </Typography> */}
      { invoice.documents.length > 0 ? 
        <Table>
          {/* <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            {invoice.documents.map((doc) => (
              <TableRow key={doc.id}>
                <TableCell>{doc.name}</TableCell>
                <TableCell align="right">
                  <DocQuickPreviewButton record={doc} />
                </TableCell>
              </TableRow>
              )
            )}
          </TableBody>
        </Table>
        : 
        <Typography variant="span" gutterBottom>
        Aucun document trouvé !
        </Typography>
      }
    </Card>
  );
};

const RecuShow = (props) => {
  const { record } = useShowController(props);

  if (!record) return null;
  return (
    <DocTable invoice={record} />
  );
};

const useStyles = makeStyles({
  root: { width: '75%', margin: 'auto', padding: '15px' },
  spacer: { height: 20 },
});

export default RecuShow;