
import { CHANGE_THEME, CHANGE_TOKEN } from './configuration/actions';


const themeReducer = (previousState = 'light', action) => {
    if (action.type === CHANGE_THEME) {
        return action.payload;
    }
    return previousState;
};

const tokenReducer = (currentToken = localStorage.getItem('token'), action) => {
    if (action.type === CHANGE_TOKEN) {
        // if (action.payload !== '') {
            localStorage.setItem('token', action.payload);
            const permissions = localStorage.getItem('permissions');
            localStorage.setItem('permissions', `${permissions}+client`);
            return action.payload;
        // }
        // return currentToken;
    }
    return currentToken;
}

export {themeReducer, tokenReducer};