import MoneyIcon from '@material-ui/icons/Money';
import ProjetList from './List';
import ProjetShow from './Show';

const projets = {
  list: ProjetList,
  show: ProjetShow,
  icon: MoneyIcon,
};

export default projets;