
export const CHANGE_THEME = 'CHANGE_THEME';
export const CHANGE_TOKEN = 'CHANGE_TOKEN';

export const changeTheme = (theme) => ({
    type: CHANGE_THEME,
    payload: theme,
});

export const changeToken = (token) => ({
    type: CHANGE_TOKEN,
    payload: token,
});