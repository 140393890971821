import React from 'react';
import {List, Datagrid, TextField, BooleanField, DateField, NumberField} from 'react-admin';


const TicketList = (props) => {
    return (
        <List {...props} bulkActionButtons={false}>
            <Datagrid rowClick="expand">
                <TextField source="id" label="ID"/>
                <TextField source="ref" label="Réference"/>
                <DateField source="date" type="date" locales="fr-FR" label="Date de facturation" />
                <NumberField source="amount" label="Montant" locales="fr-FR" options={{ style: 'currency', currency: 'XOF'}} />
                <NumberField source="paid_amount" label="Montant payé" locales="fr-FR" options={{ style: 'currency', currency: 'XOF'}} />
                <BooleanField source="paye" label="Payé"/>
            </Datagrid>
        </List>
    );
}

export default TicketList;