import DocList from './DocList';
import DocShow from './DocShow';
import MoneyIcon from '@material-ui/icons/Money';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

const fonds = {
  list: DocList,
  show: DocShow,
  icon: MoneyIcon,
  invoiceIcon: MoneyIcon,
  paymentIcon: LocalAtmIcon,
};

export default fonds;