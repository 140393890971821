import React from 'react';
import {List, Datagrid, TextField} from 'react-admin';

import DossierShow from './DossierShow';

const DossierList = (props) => {
    return (
        <List {...props} bulkActionButtons={false}>
            <Datagrid rowClick="expand" expand={<DossierShow />}>
                <TextField source="label" label="Type de document"/>
            </Datagrid>
        </List>
    );
}

export default DossierList;