import * as React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Card,
    CardContent,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    rightAlignedCell: { textAlign: 'right' },
    //product: { backgroundColor: '#0068b0'},
    //payment: { backgroundColor: '#00b1cb'},
});

const ProductTable = ({ invoice }) => {
  const classes = useStyles();
  if (! Array.isArray(invoice.products)) return null;
  return (
  <Card className={classes.product}>
    <CardContent>
      <Typography variant="h5" gutterBottom>
      Produits
      </Typography>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Libellé
                    </TableCell>
                    <TableCell>
                        Description
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                        Prix
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {invoice.products.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      {item.label}
                    </TableCell>
                    <TableCell>
                      {item.description}
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                      {new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'XOF'}).format(item.price)}
                    </TableCell>
                  </TableRow>
                  )
                )}
            </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

const PaymentTable = ({ invoice }) => {
  const classes = useStyles();
  if (! Array.isArray(invoice.payments)) return null;
  return (
  <Card className={classes.payment}>
    <CardContent>
      <Typography variant="h5" gutterBottom>
      Paiements
      </Typography>
      <Table>
          <TableHead>
              <TableRow>
                  <TableCell>
                      Référence
                  </TableCell>
                  <TableCell>
                      Numéro
                  </TableCell>
                  <TableCell>
                      Date
                  </TableCell>
                  <TableCell className={classes.rightAlignedCell}>
                      Montant
                  </TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
              {invoice.payments.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {item.ref}
                  </TableCell>
                  <TableCell>
                    {item.numero}
                  </TableCell>
                  <TableCell>
                    {new Intl.DateTimeFormat('fr-FR').format(new Date(item.date))}
                  </TableCell>
                  <TableCell className={classes.rightAlignedCell}>
                    {new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'XOF'}).format(item.amount)}
                  </TableCell>
                </TableRow>
                )
              )}
          </TableBody>
      </Table>
    </CardContent>
  </Card>
  );
};


const Basket = ({ invoice }) => {
  const classes = useStyles();
  return (
    <>
      <ProductTable invoice={invoice} />
      <div className={classes.spacer}>&nbsp;</div> 
      <PaymentTable invoice={invoice} />
    </>
  );
};
export default Basket;