import * as React from 'react';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';


const PropertyCount = ({ value }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            to=""
            icon={HomeWorkIcon}
            title={translate('pos.dashboard.property_count')}
            subtitle={value}
            color='#b0ce42'
        />
    );
};

export default PropertyCount;