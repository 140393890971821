import * as React from 'react';
import HouseIcon from '@material-ui/icons/House';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';


const InvoiceTotal = ({ value }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/invoices"
            icon={HouseIcon}
            title={translate('pos.dashboard.invoice_total')}
            subtitle={value}
            color='#0068b0'
        />
    );
};

export default InvoiceTotal;