
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useSelector,} from 'react-redux';

import { useVersion, useDataProvider } from 'react-admin';
import { Card, CardContent, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em' },
});

const Impersonate = () => {
  const [state, setState] = useState({users: []});
  const [value, setValue] = useState({});
  const classes = useStyles();
  const theme = useSelector((state) => state.theme);
  const token = useSelector((state) => state.token);
  const dataProvider = useDataProvider();
  const version = useVersion();

  const fetchUsers = useCallback(async () => {
    const { data: rows } = await dataProvider.getList('clients', {
      filter: { },
      sort: { field: 'id', order: 'ASC' },
      pagination: { page: 1, perPage: 5000 },
    });
    var users = rows.reduce((results, row) => {
      if (row.email) {
        results.push({
          'token': row.token, 'name': row.name + ' - ' + row.email
        });
      }
      return results;
    }, []);

    setState(state => ({
      ...state,
      users,
    }));
  }, [dataProvider]);

  useEffect(() => {
    fetchUsers();
  }, [version]);

  const {
    users
  } = state;

  const dispatchChangeToken = (client) => {
    console.log(client.token);
    // Lui donner le token du client
    if (client && client.token !== '' && client.token !== null) {
      // Sauvegarder l'ancien token
      localStorage.setItem('old_token', token);
      localStorage.setItem('token', client.token);
      localStorage.setItem('permissions', 'client');
      localStorage.setItem('impersonating', 'true');
      localStorage.setItem('client', client.name);
    }
    window.location.reload();
    window.location.href = '/';
    //return newToken !== '' ? dispatch(changeToken(newToken)) : null;
  }
    return (
        <Card>
            <Title title="Imiter un client" />
            <CardContent>
                <div >
                <Typography variant="h5" component="h2">
                Sélectionner le client à imiter
                </Typography>
                </div>
                <p>&nbsp;</p>
                <div>
                <Autocomplete
                  id="user-combo"
                  autoComplete={true}
                  options={users}
                  getOptionLabel={(option) => option.name}
                  style={{ width: "100%" }}
                  renderInput={(params) => <TextField {...params} label="Clients" variant="outlined" />}
                  onChange={(event, selectedClient) => {
                    return setValue(selectedClient);
                  }}
                />
                </div>
                <p>&nbsp;</p>
                <Button
                  variant="contained"
                  className={classes.button}
                  color={theme === 'light' ? 'primary' : 'default'}
                  onClick={() => dispatchChangeToken(value)}
                >
                  Imiter
                </Button>
            </CardContent>
        </Card>
    );
};

export default Impersonate;