import * as React from 'react';
import { Layout, Sidebar } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { lightTheme } from './themes';

const CustomSidebar = (props) => <Sidebar {...props} size={200} />;

const AppLayout = (props) => {
    return (
        <Layout
            {...props}
            appBar={AppBar}
            sidebar={CustomSidebar}
            menu={Menu}
            theme={lightTheme}
        />
    );
};

export default AppLayout;