import * as React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    main: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: -8,
        marginBottom: -8,
    },
    chip: { margin: 4 },
});

const PermissionsField = ({ record }) => {
    const classes = useStyles();
    var permissions = null;
    if (record && record.permission) {
      permissions = record.permission.split(',');
    }
    return permissions ? (
      <span className={classes.main}>
        { 
          permissions.map(permission => {
            return permission ? (<Chip
              size="small"
              key={permission}
              className={classes.chip}
              label={permission}
            />) : null;
          })
        } 
      </span>
    ) : null;
};

export default PermissionsField;