import { nominalTypeHack } from "prop-types";
import bgImage from './Sablux_Sablier.png';

export const darkTheme = {
    palette: {
        primary: {
            main: '#8f4396',
        },
        secondary: {
            main: '#b1b3b6',
        },
        type: 'dark', // Switching the dark mode on is a single property value change.
    },
    shape: {
        borderRadius: 3,
    },
    overrides: {
        MuiAppBar: {
            colorSecondary: {
                color: '#ffffff',
                backgroundColor: '#8f4396',
            },

        },
        MuiPaper: {
            root: {
                border: 'none',
            }
        },
        MuiButtonBase: {
            root: {
                '&:hover:active::after': {
                    // recreate a static ripple color
                    // use the currentColor to make it work both for outlined and contained buttons
                    // but to dim the background without dimming the text,
                    // put another element on top with a limited opacity
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'currentColor',
                    opacity: 0.3,
                    borderRadius: 'inherit',
                },
            },
        },
    },
    props: {
        MuiButtonBase: {
            // disable ripple for perf reasons
            disableRipple: true,
        },
    },
};

export const lightTheme = {
    palette: {
        primary: {
            main: '#8f4396',
        },
        secondary: {
            light: '#5f5fc4',
            main: '#b1b3b6',
            dark: '#001064',
            contrastText: '#fff',
        },
        background: {
            default: '#fcfcfe',
        },
        type: 'light',
    },
    shape: {
        borderRadius: 5,
    },
    overrides: {
        RaAppBar: {
            toolbar: {
                //boxShadow: '0 4px 2px -2px #b1b3b6',
            }
        },
        RaMenuItemLink: {
            root: {
                color: '#333',
                '&:hover': {
                    borderLeft: '5px solid #8f4396',
                    borderRadius: '0 5px 5px 0',
                    color: '#ffffff',
                    backgroundColor: '#8f4396',
                },
            },
            active: {
                borderLeft: '5px solid #8f4396',
                borderRadius: '0 5px 5px 0',
                color: '#ffffff',
                backgroundColor: '#8f4396',
            },
        },
        RaSidebar: {
            drawerPaper: {
                minWidth: '300px',
                //height: '100vh',
                boxShadow: '0px 0px 10px 0px #b1b3b6',
            }
        },
        RaLayout: {
            contentWithSidebar: {
                backgroundColor: '#fff',
                background: `url(${bgImage})  top right / contain no-repeat`,
                //backgroundPosition: 'right bottom',
                //backgroundRepeat: 'no-repeat',
                alignItems: 'strech',
            },
            content: {
                paddingRight: '5px',
                //height: '100vh',
            }
        },
        RaDatagrid: {
            headerCell: {
                padding: '15px 20px',
                backgroundColor: '#8f4396',
                color: '#ffffff',
                fontWeight: 'bold',
            }
        },
        RaButton: {
            button: {
                backgroundColor: '#8f4396',
                color: '#ffffff',
            }
        },
        MuiTablePagination: {
            toolbar: {
                backgroundColor: '#b1b3b6',
                color: '#000',
                fontWeight: 'bold',
            }
        },
        MuiTableCell: {
            root: {
                borderBottom: '1px solid #b1b3b6',
            },
            head: {
                fontWeight: 'bold',
                color: '#8f4396',
                padding: '5px 5px',
            },
            body: {
                padding: '3px 5px',
                backgroundColor: '#ddd',
            }
        },
        //   MuiPaper: {
        //       elevation1: {
        //           boxShadow: 'none',
        //       },
        //       root: {
        //           backgroundClip: 'padding-box',
        //       },
        //   },
        MuiButton: {
            contained: {
                backgroundColor: '#8f4396',
                color: '#ffffff',
                // backgroundColor: '#fff',
                // color: '#4f3cc9',
                boxShadow: 'none',
            },
        },
        MuiButtonBase: {
            root: {
                '&:hover:active::after': {
                    // recreate a static ripple color
                    // use the currentColor to make it work both for outlined and contained buttons
                    // but to dim the background without dimming the text,
                    // put another element on top with a limited opacity
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'currentColor',
                    opacity: 0.3,
                    borderRadius: 'inherit',
                },
            },
        },
        MuiAppBar: {
            colorSecondary: {
                color: '#ffffff',
                backgroundColor: '#8f4396',
            },
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: '#f5f5f5',
            },
            barColorPrimary: {
                backgroundColor: '#d7d7d7',
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiSnackbarContent: {
            root: {
                border: 'none',
            },
        },
    },
    props: {
        MuiButtonBase: {
            // disable ripple for perf reasons
            disableRipple: true,
        },
    },
};