import PhotoList from './List';
import PhotoShow from './Show';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';

const photos = {
  list: PhotoList,
  show: PhotoShow,
  icon: PhotoAlbumIcon,
};

export default photos;