
import UserList from './UserList';
import UserEdit from './UserEdit';

import GroupIcon from '@material-ui/icons/GroupRounded';

const users = {
    list: UserList,
    edit: UserEdit,
    icon: GroupIcon,
}
export default users;