import React from 'react';
import {List, Datagrid, TextField, NumberField, DateField} from 'react-admin';
import DocQuickPreviewButton from '../DocPreviewButton';

const DocList = (props) => {
    return (
        <List {...props} bulkActionButtons={false} exporter={false}>
            <Datagrid>
                {/* <TextField source="id" label="ID"/> */}
                <TextField source="program" label="Programme"/>
                <TextField source="label" label="Appel de fond"/>
                <DateField source="date" type="date" locales="fr-FR" label="Date" />
                <NumberField source="amount" label="Montant appelé" locales="fr-FR" options={{style: 'currency', currency: 'XOF', minimumFractionDigits: 0, maximumFractionDigits: 0}}/>
                <NumberField source="paid_amount" label="Montant payé" locales="fr-FR" options={{style: 'currency', currency: 'XOF', minimumFractionDigits: 0, maximumFractionDigits: 0}}/>
                <DocQuickPreviewButton />
            </Datagrid>
        </List>
    );
}

export default DocList;