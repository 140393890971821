import React from 'react';
import {List, Datagrid, TextField} from 'react-admin';
import PhotoShow from './Show';

const PhotoList = (props) => {
    return (
        <List {...props} bulkActionButtons={false}>
            <Datagrid rowClick="expand" expand={<PhotoShow />}>
                <TextField source="label" label="Programme"/>
            </Datagrid>
        </List>
    );
}

export default PhotoList;