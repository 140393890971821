import * as React from 'react';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';


const ApartmentCount = ({ value }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            to=""
            icon={ApartmentIcon}
            title={translate('pos.dashboard.apartment_count')}
            subtitle={value}
            color='#f59331'
        />
    );
};

export default ApartmentCount;