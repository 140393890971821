// in PostQuickPreviewButton.js
import React from "react";
import { withStyles } from "@material-ui/core/styles";

import IconImageEye from "@material-ui/icons/RemoveRedEye";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#8f4396',
    color: '#ffffff',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: '#8f4396',
    color: '#ffffff',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiDialogContent);

const DocQuickPreviewButton = ({record}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen} variant="contained"
        size="small"
        startIcon={<IconImageEye />}
      >
        Afficher
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true} maxWidth="md">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Aperçu
        </DialogTitle>
        <DialogContent dividers>
          <object data={record.content} type="application/pdf" width="100%" height="500">Aperçu non disponible</object>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DocQuickPreviewButton;