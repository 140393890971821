import React from 'react';
import {List, Datagrid, TextField, DateField, NumberField} from 'react-admin';

import RecuShow from './RecuShow';

const RecuList = (props) => {
    return (
        <List {...props} bulkActionButtons={false} title="Mes reçus de versements">
            <Datagrid rowClick="expand" expand={<RecuShow />}>
                {/* <TextField source="id" label="ID"/> */}
                <TextField source="ref" label="Réference"/>
                <DateField source="date" type="date" locales="fr-FR" label="Date de facturation" />
                <NumberField source="amount" label="Montant" locales="fr-FR" options={{ style: 'currency', currency: 'XOF'}} />
                <NumberField source="paid_amount" label="Montant payé" locales="fr-FR" options={{ style: 'currency', currency: 'XOF'}} />
                {/* <BooleanField source="paye" label="Payé"/> */}
            </Datagrid>
        </List>
    );
}

export default RecuList;