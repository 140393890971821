import AssignmentIcon from '@material-ui/icons/Assignment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import MoneyIcon from '@material-ui/icons/Money';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

const documents = {
  icon: AssignmentIcon,
  contractIcon: AssignmentTurnedInIcon,
  invoiceIcon: MoneyIcon,
  fundIcon: AccountBalanceWalletIcon,
  receiptIcon: ReceiptIcon,
};

export default documents;