import frenchMessages from 'ra-language-french';

const englishMessages = {
    ...frenchMessages,
    pos: {
        search: 'Rechercher',
        configuration: 'Configuration',
        language: 'Langue',
        theme: {
            name: 'Theme',
            light: 'Clair',
            dark: 'Obscur',
        },
        dashboard: {
            paiement_graph: 'Montants payés et restants',
            amount: 'Montant',
            paid: 'Payé',
            invoice_total: "Valeur de mes biens",
            payment_total: "Mes versements",
            remaining: 'Mon reliquat',
            property_count: "Nombre de biens",
            apartment_count: "Nombre d'appartements",
            parking_count: "Nombre de parking",
            welcome: {
                title: "Bienvenue sur l'espace client de Sablux Immobilier",
                subtitle:
                    "Ceci est l'espace des clients de Sablux Immoblier pour vous fournir toutes les informations relatives à vos biens et voir l'état d'avancement de vos paiements. N'hésitez pas à explorer pour voir toutes les fonctionnalités disponibles.",
                ra_button: 'Site web',
                demo_button: 'Nouveaux programmes',
            },
        },
        menu: {
            sales: 'Ventes',
            catalog: 'Catalogue',
            customers: 'Clients',
        },
    },
    resources: {
        users: 'Utilisateurs',
        internal_users: 'Utilisateurs',
        external_users: 'Clients',
        contact: 'Contact',
        configuration: 'Configuration',
        suivi: 'Évolution du programme',
        documents: 'Mes documents',
        dossiers: {
            empty: "Votre dossier client est vide !",
            name: 'Mon dossier client',
        },
        fonds: {
            empty: "Aucun appel de fonds trouvé !",
            name: 'Mes appels de fonds',
        },
        recus: {
            empty: "Aucun reçu ou attestation trouvé !",
            name: 'Mes reçus de versements',
        },
        projets: {
            empty: "En cours de developpement ...",
            name: 'Projets',
        },
        programmes: {
            empty: "En cours de developpement ...",
            name: 'Programmes',
        },
        photos: {
            empty: "En cours de developpement ...",
            name: "Photos d'évolution",
        },
        tickets: {
            empty: "En cours de developpement ...",
            name: 'Mes demandes',
        },
        invoices: {
            empty: "Aucune facture trouvée",
            name: 'Mes réservations',
            fields: {
                id: 'Numéro',
                date: 'Date de facture',
                customer_id: 'Client',
                command_id: 'Commande',
                date_gte: 'Emises depuis',
                date_lte: 'Emises avant',
                address: 'Adresse',
                total_ex_taxes: 'Montant HT',
                delivery_fees: 'Frais de livraison',
                taxes: 'TVA',
            },
        },
        products: {
            name: 'Poster |||| Posters',
            fields: {
                category_id: 'Catégorie',
                height_gte: 'Hauteur mini',
                height_lte: 'Hauteur maxi',
                height: 'Hauteur',
                image: 'Photo',
                price: 'Prix',
                reference: 'Référence',
                sales: 'Ventes',
                stock_lte: 'Stock faible',
                stock: 'Stock',
                thumbnail: 'Aperçu',
                width_gte: 'Largeur mini',
                width_lte: 'Margeur maxi',
                width: 'Largeur',
            },
        },
    },
};

export default englishMessages;