import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import BuildIcon from '@material-ui/icons/Build';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { useMediaQuery, Box } from '@material-ui/core';
import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    usePermissions,
} from 'react-admin';

import invoices from '../reservations';
import users from '../clients';
import internalusers from '../utilisateurs';
import documents from '../documents';
import SubMenu from './SubMenu';
import dossiers from '../dossiers';
import recus from '../recus';
import photos from '../photos';

//type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';
//const MenuName = 'menuUsers' | 'menuInvoices' | 'menuDocuments';

const Menu = ({ onMenuClick, logout, dense = false }) => {
    const [state, setState] = useState({
        menuUsers: true,
        menuInvoices: true,
        menuDocuments: true,
        menuCommandes: true,
    });
    const translate = useTranslate();
    const { permissions } = usePermissions();
    console.log(typeof(permissions) + ': ' + permissions);
    const isXSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    useSelector((state) => state.theme); // force rerender on theme change

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box mt={1}>
            {' '}
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            { (permissions === 'admin' || permissions === 'impersonate') &&
            <SubMenu
                handleToggle={() => handleToggle('menuUsers')}
                isOpen={state.menuUsers}
                sidebarIsOpen={open}
                name="resources.users"
                icon={<users.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/clients`}
                    primaryText={translate(`resources.external_users`)}
                    leftIcon={<users.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/utilisateurs`}
                    primaryText={translate(`resources.internal_users`)}
                    leftIcon={<internalusers.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            }
            { permissions === 'client' &&
            <SubMenu
                handleToggle={() => handleToggle('menuInvoices')}
                isOpen={state.menuInvoices}
                sidebarIsOpen={open}
                name="resources.invoices.name"
                icon={<invoices.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/reservations`}
                    primaryText={translate(`resources.invoices.name`)}
                    leftIcon={<invoices.invoiceIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/fonds`}
                    primaryText={translate(`resources.fonds.name`)}
                    leftIcon={<documents.fundIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            }
            { permissions === 'client' &&
            <SubMenu
                handleToggle={() => handleToggle('menuDocuments')}
                isOpen={state.menuDocuments}
                sidebarIsOpen={open}
                name="resources.documents"
                icon={<documents.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/dossiers`}
                    primaryText={translate(`resources.dossiers.name`)}
                    leftIcon={<dossiers.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/recus`}
                    primaryText={translate(`resources.recus.name`)}
                    leftIcon={<recus.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            }
            { permissions === 'client' &&
            <SubMenu
                handleToggle={() => handleToggle('menuCommandes')}
                isOpen={state.menuCommandes}
                sidebarIsOpen={open}
                name="resources.suivi"
                icon={<documents.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to={`/photos`}
                    primaryText={translate(`resources.photos.name`)}
                    leftIcon={<photos.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to="/tickets"
                    primaryText={translate(`resources.tickets.name`)}
                    leftIcon={<PlaylistAddCheckIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            }
            <MenuItemLink
                to={`/projets`}
                primaryText={translate(`resources.projets.name`)}
                leftIcon={<BuildIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            { permissions === 'client' &&
            <MenuItemLink
                to="/contact"
                primaryText={translate(`resources.contact`)}
                leftIcon={<ContactPhoneIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            }
            {isXSmall && (
                <MenuItemLink
                    to="/configuration"
                    primaryText={translate(`resources.configuration`)}
                    leftIcon={<SettingsIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}
            {isXSmall && logout}
        </Box>
    );
};

export default Menu;