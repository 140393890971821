

import { 
  Card,
  CardHeader,
  CardContent,
  TextField,
  Button,
  Typography
} from '@material-ui/core';
import { createMuiTheme, withStyles, makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

import { Title } from 'react-admin';

const ColorButton = withStyles((theme) => ({
  root: {
    color: '#fff',
    backgroundColor: '#8f4396',
    '&:hover': {
      backgroundColor: '#b1b3b6',
    },
  },
}))(Button);

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
  },
  media: {
    height: 140,
  },
  margin: {
    margin: theme.spacing(1),
  },
});

function createData(name, phone, email, address) {
  return { name, phone, email, address };
}

const rows = [
  createData('Service client', '(+221)xx xxx xx xx', 'contact@sabluxgroup.com', 'Point E, Rue YY x ZZ'),
  createData('Service commercial', '(+221)xx xxx xx xx', 'contact@sabluxgroup.com', 'Point E, Rue YY x ZZ'),
  createData('Service financier', '(+221)xx xxx xx xx', 'contact@sabluxgroup.com', 'Point E, Rue YY x ZZ'),
  createData('Service légal', '(+221)xx xxx xx xx', 'contact@sabluxgroup.com', 'Point E, Rue YY x ZZ'),
];

const Contact = () => {
  const classes = useStyles();
    return (
        <Card className={classes.root}>
            <Title title="Contacter Sablux Immobilier" />
            <CardHeader>
              <Typography variant="h5" component="h2">
                Contact Contacter Sablux Immobilier
              </Typography>
            </CardHeader>
            <CardContent>
                {/* <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="contact table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nom</TableCell>
                        <TableCell>Téléphone</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Adresse</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">{row.name}</TableCell>
                          <TableCell>{row.phone}</TableCell>
                          <TableCell>{row.email}</TableCell>
                          <TableCell>{row.address}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer> */}
                <form className={classes.root} noValidate autoComplete="off">
                  {/* <Typography variant="h5" component="h2">
                    Vos coordonnées personnelles
                  </Typography>
                  <TextField id="prenom" label="Prénom" variant="outlined" style={{ margin: 8 }} margin="normal" fullWidth />
                  <TextField id="nom" label="Nom" variant="outlined" style={{ margin: 8 }} margin="normal" fullWidth />
                  <TextField id="emaol" label="Adresse e-mail" variant="outlined" style={{ margin: 8 }} margin="normal" fullWidth />
                  <Typography variant="h5" component="h2">
                    Pour vous contacter
                  </Typography>
                  <TextField id="phone" label="Téléphone" variant="outlined" style={{ margin: 8 }} margin="normal" fullWidth />
                  <TextField id="mobile" label="Téléphone mobile" variant="outlined" style={{ margin: 8 }} margin="normal" fullWidth /> */}
                  <Typography variant="h5" component="h2">
                    Votre demande
                  </Typography>
                  <TextField id="message" label="Message" variant="outlined" multiline rows={20} rowsMax={30} style={{ margin: 8 }} margin="normal" fullWidth/>
                  <ColorButton variant="contained" color="primary" className={classes.margin} onClick={() => { alert('Votre message est envoyé au service client qui vous contactera incessamment.') }}>Envoyer</ColorButton>
                </form>
            </CardContent>
        </Card>
    );
};

export default Contact;