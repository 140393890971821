import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useVersion, useDataProvider, usePermissions } from 'react-admin';
import { useMediaQuery} from '@material-ui/core';

import Welcome from './Welcome';
import InvoiceTotal from './InvoiceTotal';
import PaymentTotal from './PaymentTotal';
import Remaining from './Remaining';
import PropertyCount from './PropertyCount';
import ApartmentCount from './ApartmentCount';
import ParkingCount from './ParkingCount';


const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const Dashboard = () => {
  const [state, setState] = useState({
    invoiceTotal: 0,
    paymentTotal: 0,
    remaining: 0,
    invoices: [],
    parkingCount: 0,
    apartmentCount: 0,
    propertyCount: 0,
    products: []
  });
  const version = useVersion();
  const dataProvider = useDataProvider();
  const {permissions} = usePermissions();
  const isXSmall = useMediaQuery((theme) =>
    theme.breakpoints.down('xs')
  );
  const isSmall = useMediaQuery((theme) =>
    theme.breakpoints.down('md')
  );

  const fetchInvoices = useCallback(async () => {
    const { data: invoices } = await dataProvider.getList('reservations', {
      filter: { },
      sort: { field: 'date', order: 'ASC' },
      pagination: { page: 1, perPage: 50 },
    });
    const aggregations = invoices.reduce((stats, invoice) => {
      stats.invoiceTotal += parseInt(invoice.amount);
      stats.paymentTotal += parseInt(invoice.paid_amount);
      return stats;
    },
    {
      invoiceTotal: 0,
      paymentTotal: 0,
    });
    const difference = aggregations.invoiceTotal - aggregations.paymentTotal;
    setState(state => ({
      ...state,
      invoices,
      remaining: difference.toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'XOF',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }),
      invoiceTotal: aggregations.invoiceTotal.toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'XOF',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      paymentTotal: aggregations.paymentTotal.toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'XOF',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    }));
  }, [dataProvider]);

  const fetchProducts = useCallback(async () => {
    const { data: products } = await dataProvider.getList('produits', {
      filter: { },
      sort: { field: 'ref', order: 'ASC' },
      pagination: { page: 1, perPage: 50 },
    });
    const aggregations = products.reduce((stats, prod) => {
      if (prod.type === 'Parking') {
        stats.parkingCount += 1;
      } else {
        stats.apartmentCount += 1;
      }
      stats.propertyCount += 1;
      return stats;
    },
    {
      parkingCount: 0,
      apartmentCount: 0,
      propertyCount: 0,
    });
    setState(state => ({
      ...state,
      products,
      parkingCount: aggregations.parkingCount,
      apartmentCount: aggregations.apartmentCount,
      propertyCount: aggregations.propertyCount,
    }));
  }, [dataProvider]);

  useEffect(() => {
    fetchInvoices();
    fetchProducts();
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
      invoiceTotal,
      paymentTotal,
      remaining,
      parkingCount,
      apartmentCount,
      propertyCount,
  } = state;

  return isXSmall ? (
      <div>
        <div style={styles.flexColumn}>
          <Welcome />
          { permissions === 'client' &&
          <>
          <InvoiceTotal value={invoiceTotal} />
          <VerticalSpacer />
          <PaymentTotal value={paymentTotal} />
          <VerticalSpacer />
          <Remaining value={remaining} />
          <PropertyCount value={propertyCount} />
          <VerticalSpacer />
          <ApartmentCount value={apartmentCount} />
          <VerticalSpacer />
          <ParkingCount value={parkingCount} />
          </>
          }
        </div>
      </div>
  ) : isSmall ? (
      <div style={styles.flexColumn}>
        <div style={styles.singleCol}>
          <Welcome />
        </div>
        { permissions === 'client' &&
        <>
        <div style={styles.flex}>
          <InvoiceTotal value={invoiceTotal} />
          <Spacer />
          <PaymentTotal value={paymentTotal} />
          <Spacer />
          <Remaining value={remaining} />
        </div>
        <div style={styles.flex}>
          <PropertyCount value={propertyCount} />
          <Spacer />
          <ApartmentCount value={apartmentCount} />
          <Spacer />
          <ParkingCount value={parkingCount} />
        </div>
        </>
        }
      </div>
  ) : (
      <>
        <Welcome />
        { permissions === 'client' &&
        <div style={styles.flex}>
          <div style={styles.leftCol}>
            <div style={styles.flex}>
              <InvoiceTotal value={invoiceTotal} />
              <Spacer />
              <PaymentTotal value={paymentTotal} />
              <Spacer />
              <Remaining value={remaining} />
            </div>
            <div>&nbsp;</div>
            <div style={styles.flex}>
              <PropertyCount value={propertyCount} />
              <Spacer />
              <ApartmentCount value={apartmentCount} />
              <Spacer />
              <ParkingCount value={parkingCount > 0 ? parkingCount : 0 } />
            </div>
          </div>
        </div>
        }
      </>
  );
};

export default Dashboard;