import {React, useState, useEffect} from 'react';
import { Box, Card, CardActions, Button, Typography } from '@material-ui/core';
import PublicIcon from '@material-ui/icons/Public';
import WhatshotIcon from '@material-ui/icons/Whatshot';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';

//import Carousel from 'react-material-ui-carousel'

import corailImage from './CORAIL.png';
import oceaneImage from './OCEANE.png';
import carlineImage from './CARLINE.png';
import atlantikaImage from './ATLANTIKA.jpg';

const useStyles = makeStyles(theme => ({
    root: {
        background: '#8f4396',
        color: '#fff',
        padding: 0,
        marginBottom: '3em',
        minHeight: '50%',
    },
    welcome: {
        flexFlo: 'row wrap',
        justifyContent: 'space-around',
        alignItems: 'stretch',
        margin: 0,
        padding: 0,
        height: '100%',
    },
    wtext: {
        margin: 20,
    },
    wimage: {
        margin: 0,
        padding: 0,
    },
    media: {
        background: `url(${corailImage}) top right / cover`,
        marginLeft: 'auto',
    },
    mediaCo: {
        background: `url(${corailImage}) top right / contain no-repeat`,
        marginLeft: 'auto',
    },
    mediaCa: {
        background: `url(${carlineImage}) top right / contain no-repeat`,
        marginLeft: 'auto',
    },
    mediaOc: {
        background: `url(${oceaneImage}) top right / contain no-repeat`,
        marginLeft: 'auto',
    },
    mediaAt: {
        background: `url(${atlantikaImage}) top right / contain no-repeat`,
        marginLeft: 'auto',
    },
    actions: {
        [theme.breakpoints.down('md')]: {
            padding: 0,
            flexWrap: 'wrap',
            '& a': {
                marginTop: '1em',
                marginLeft: '0!important',
                marginRight: '1em',
            },
        },
    },
}));

const Welcome = () => {
    const translate = useTranslate();
    const classes = useStyles();
    const medias = [classes.mediaAt, classes.mediaCo, classes.mediaOc, classes.mediaCa];
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(index => (index + 1 + 4) % 4);
        }, 5000);
        return () => clearInterval(interval);
    }, []);


    return (
        <Card className={classes.root}>
            <Box display="flex" className={classes.welcome}>
                <Box flex="1" className={classes.wtext}>
                    <Typography variant="h5" component="h2" gutterBottom>
                    Bienvenue dans l’espace client de Sablux Immobilier
                    </Typography>
                    <Box maxWidth="40em">
                        <Typography variant="body1" component="p" gutterBottom>
                        Laissez-vous emporter dans une expérience client unique. 
                        </Typography>
                        <ul>
                        <li>Suivez l’état de votre réservation</li>
                        <li>Faites vos demandes ponctuelles</li>
                        <li>Restez informé</li>
                        </ul>
                    </Box>
                    <Typography variant="h5" component="h3" gutterBottom>
                        Ensemble, réussissons votre projet.
                    </Typography>
                    <CardActions className={classes.actions}>
                        <Button
                            variant="contained"
                            href="http://sabluxholding.com/"
                            startIcon={<PublicIcon />}
                        >
                            {translate('pos.dashboard.welcome.ra_button')}
                        </Button>
                        <Button
                            variant="contained"
                            href="http://sabluxholding.com/immo"
                            startIcon={<WhatshotIcon />}
                        >
                            {translate('pos.dashboard.welcome.demo_button')}
                        </Button>
                    </CardActions>
                </Box>
                <Box flex="1" 
                    display={{ xs: 'none', sm: 'none', md: 'block' }}
                    className={medias[index]}
                    overflow="hidden"
                />
            </Box>
        </Card>
    );
};

export default Welcome;