import * as React from 'react';
import { forwardRef } from 'react';
import { AppBar, UserMenu, MenuItemLink } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import FaceIcon from '@material-ui/icons/Face';
import FaceTwoToneIcon from '@material-ui/icons/FaceTwoTone';
import SupervisedUserCircleTwoToneIcon from '@material-ui/icons/SupervisedUserCircleTwoTone';
import { makeStyles } from '@material-ui/core/styles';
import {usePermissions} from 'react-admin';

import Logo from './Logo';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const ConfigurationMenu = forwardRef((props, ref) => {
    return (
        <MenuItemLink
            ref={ref}
            to="/configuration"
            primaryText="Configuration"
            leftIcon={<SettingsIcon />}
            onClick={props.onClick}
            sidebarIsOpen
        />
    );
});

const ImpersonationMenu = forwardRef((props, ref) => {
    return (
        <MenuItemLink
            ref={ref}
            to="/impersonate"
            primaryText="Imiter un client"
            leftIcon={<FaceIcon />}
            onClick={props.onClick}
            sidebarIsOpen
        />
    );
});

const QuitImpersonation = () => {
    localStorage.setItem('token', localStorage.getItem('old_token'));
    localStorage.setItem('permissions', 'impersonate');
    localStorage.removeItem('impersonating');
    localStorage.removeItem('old_token');
    localStorage.removeItem('client');

    window.location.href = '/';
}

const QuitImpersonationMenu = forwardRef((props, ref) => {
    return (
        <MenuItemLink
            ref={ref}
            to="/"
            primaryText="Quitter l'imitation client"
            leftIcon={<FaceTwoToneIcon />}
            onClick={QuitImpersonation}
            sidebarIsOpen
        />
    );
});

const ImpersonationTitle = () => {
    const client = localStorage.getItem('client');
    return (
        <>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', }}>
            <SupervisedUserCircleTwoToneIcon />
            <span><b>{client}</b></span>
        </div> 
        </>
    );
}

const CustomUserMenu = (props) => {
    const { permissions } = usePermissions();
    return (
        <UserMenu {...props}>
            <ConfigurationMenu />
            { permissions && permissions.includes('impersonate') &&
            <ImpersonationMenu />
            }
            { localStorage.getItem('impersonating') === 'true' && 
            <QuitImpersonationMenu />
            }
        </UserMenu>
    );
}

const CustomAppBar = (props) => {
    const classes = useStyles();
    return (
        <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer} />
            { localStorage.getItem('impersonating') === 'true' && 
            <ImpersonationTitle />
            }
        </AppBar>
    );
};

export default CustomAppBar;