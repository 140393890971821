import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { useShowController } from 'react-admin';

const useStyles = makeStyles({
  root: { width: 600, margin: 'auto' },
  spacer: { height: 20 },
  invoices: { margin: '10px 0' },
});

// const DocTable = ({ invoice }) => {
//   if (! Array.isArray(invoice.documents)) return null;
//   return (
//     <>
//       <Typography variant="h5" gutterBottom>
//       Liste des documents associés à cette facture
//       </Typography>
//       { invoice.documents.length > 0 ? 
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>&nbsp;</TableCell>
//               <TableCell>&nbsp;</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {invoice.documents.map((doc) => (
//               <TableRow key={doc.id}>
//                 <TableCell>{doc.name}</TableCell>
//                 <TableCell>
//                   <DocQuickPreviewButton content={doc.content} />
//                 </TableCell>
//               </TableRow>
//               )
//             )}
//           </TableBody>
//         </Table>
//         : 
//         <Typography variant="span" gutterBottom>
//         Aucun document
//         </Typography>
//       }
//     </>
//   );
// };

const DocShow = (props) => {
  const { record } = useShowController(props);
  const classes = useStyles();

  if (!record) return null;
  return (
    <Card className={classes.root}>
      <CardContent fullWidth={true} maxWidth="md">
        <object data={record.content} type="application/pdf" width="100%" height="500">Aperçu non disponible</object>
      </CardContent>
    </Card>
  );
};

export default DocShow;