import * as React from 'react';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';


const Remaining = ({ value }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/invoices"
            icon={CreditCardIcon}
            title={translate('pos.dashboard.remaining')}
            subtitle={value}
            color='#ffca30'
        />
    );
};

export default Remaining;