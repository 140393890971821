import URL from './backend';

const md5 = require('md5');

const AuthProvider = {
  // called when the user attempts to log in
  login: ({ username, password }) =>  {
    const request = new Request(`${URL}/index.php/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ username: username, password: md5(password)}),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        // Log headers for debug
        response.headers.forEach(console.log);
        const bearerToken = response.headers.get('Authorization');
        if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
          const bearer = bearerToken.slice(7, bearerToken.length);
          // Log token for debug
          console.log(`Token: ${bearer}`)
          // Token est compose de token et des permission.
          const permissions = {
            '62608e08adc29a8d6dbc9754e659f125': 'client',
            '21232f297a57a5a743894a0e4a801fc3': 'admin',
            '7581cd24e6b3486b70b67d0434fd9ddc': 'impersonate',
          }
          const key_permission = bearer.split('.');
          localStorage.setItem('token', key_permission[0]);
          localStorage.setItem('permissions', permissions[key_permission[1]]);
        }
      })
  },
  // called when the user clicks on the logout button
  logout: () => {
    const items = ['token', 'permissions', 'old_token', 'impersonating', 'client'];
    items.forEach((item) => {
      localStorage.removeItem(item);
    });
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('permissions');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for
  // authentication
  checkAuth: () => {
    return localStorage.getItem('token')
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for
  // permissions / roles
  getPermissions: () => {
    const permissions = localStorage.getItem('permissions');
    return permissions ? Promise.resolve(permissions) : Promise.reject();
  },
};

export default AuthProvider;