import React from 'react';
import {List, Datagrid, TextField } from 'react-admin';

import ProjetShow from './Show';

const ProjetList = (props) => {
    return (
        <List {...props} bulkActionButtons={false}>
            <Datagrid rowClick="expand" expand={<ProjetShow />}>
                <TextField source="label" label="Programme"/>
                <TextField source="description" label="Description"/>
            </Datagrid>
        </List>
    );
}

export default ProjetList;