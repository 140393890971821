import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import { purple } from '@material-ui/core/colors';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import InputAdornment from '@material-ui/core/InputAdornment';
import loginImage from './sablux-immobilier-login.png';
import LogoImage from '../Sablux-Logo.png';

import {
    Avatar,
    Button,
    Card,
    CardActions,
    CircularProgress,
    TextField,
} from '@material-ui/core';
import { createMuiTheme, withStyles, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Notification, useTranslate, useLogin, useNotify } from 'react-admin';

import { lightTheme } from './themes';

const useStyles = makeStyles(theme => ({
    login: {
        minWidth: 400,
        borderRadius: 0,
        boxShadow: '0 1px 0 #fff inset',
        border: '1px solid #c4c6ca',
        margin: '0 auto',
        padding: '25px 0 0',
        position: 'relative',
        textAlign: 'center',
        textShadow: '0 1px 0 #fff',
        '&::after': {
            border: '1px solid #b1b3b6',
            content: '""',
            display: 'block',
            height: '100%',
            left: '-1px',
            position: 'absolute',
            width: '100%',
            transform: 'rotate(2deg)',
            top: 0,
            zIndex: -1,
        },
        '&::before': {
            border: '1px solid #b1b3b6',
            content: '""',
            display: 'block',
            height: '100%',
            left: '-1px',
            position: 'absolute',
            width: '100%',
            transform: 'rotate(-3deg)',
            top: 0,
            zIndex: -2,
        },
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        //background: `url(${loginImage}) no-repeat center fixed`,
        //backgroundSize: 'cover',
    },
    card: {
        minWidth: 400,
        //margin:'0 auto',
        padding: '1em 1em',
    },
    avatar: {
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        //backgroundColor: theme.palette.secondary.main,
        color: '#fff',
        backgroundColor: '#8f4396',
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
}));

const ColorButton = withStyles((theme) => ({
    root: {
      color: '#fff',
      backgroundColor: '#8f4396',
      '&:hover': {
        backgroundColor: '#b1b3b6',
        color: '#000'
      },
      borderRadius: '20px 0 20px 0',
      padding: '10px',
    },
  }))(Button);

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {props.icon}
            </InputAdornment>
          ),
        }}
    />
);

const { Form } = withTypes();

const Login = () => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const login = useLogin();
    const location = useLocation();

    const handleSubmit = (auth) => {
        setLoading(true);
        login(auth, location.state ? location.state.nextPathname : '/').catch(
            (error) => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                    'warning',
                    {
                        _:
                            typeof error === 'string'
                                ? error
                                : error && error.message
                                ? error.message
                                : undefined,
                    }
                );
            }
        );
    };

    const validate = (values) => {
        const errors = {};
        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    return (
        <>
        <div class="container-login">
        <div class="login-form">
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.main}>
                        <Card className={classes.card}>
                            <div className={classes.avatar}>
                                <img src={LogoImage} alt="Logo"/>
                            </div>
                            <div className={classes.form}>
                            <div className={classes.hint}>
                                <h2>Accedez à votre compte</h2>
                            </div>
                                <div className={classes.input}>
                                    <Field
                                        autoFocus
                                        name="username"
                                        // @ts-ignore
                                        component={renderInput}
                                        label={translate('ra.auth.username')}
                                        disabled={loading}
                                        icon={<AccountCircle/>}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        name="password"
                                        // @ts-ignore
                                        component={renderInput}
                                        label={translate('ra.auth.password')}
                                        type="password"
                                        disabled={loading}
                                        icon={<LockIcon/>}
                                    />
                                </div>
                            </div>
                            <CardActions className={classes.actions}>
                                <ColorButton
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                >
                                    {loading && (
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                        />
                                    )}
                                    Se connecter
                                </ColorButton>
                            </CardActions>
                        </Card>
                        <Notification />
                    </div>
                </form>
            )}
        />
        </div>
        <div class="login-image">
         &nbsp;
        </div>
        </div>
    </>
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props) => (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
        <Login {...props} />
    </ThemeProvider>
);

export default LoginWithTheme;