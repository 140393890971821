import React from 'react';
import {List, Datagrid, TextField, DateField, NumberField} from 'react-admin';

import InvoiceShow from './InvoiceShow';

const InvoiceList = (props) => {
    return (
        <List {...props} bulkActionButtons={false}>
            <Datagrid rowClick="expand" expand={<InvoiceShow />}>
                {/* <TextField source="id" label="ID"/> */}
                <TextField source="ref" label="Réference"/>
                <DateField source="date" type="date" locales="fr-FR" label="Date de facturation" />
                <NumberField source="amount" label="Montant" locales="fr-FR" options={{ style: 'currency', currency: 'XOF'}} />
                <NumberField source="paid_amount" label="Montant payé" locales="fr-FR" options={{ style: 'currency', currency: 'XOF'}} />
                <NumberField source="to_paid" label="Reste à payer" locales="fr-FR" options={{ style: 'currency', currency: 'XOF'}}/>
            </Datagrid>
        </List>
    );
}

export default InvoiceList;