import React from 'react';
import { Filter, TextInput } from 'react-admin';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EmailField,
    EditButton,
} from 'react-admin';

const ClientFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Chercher" source="q" alwaysOn />
        {/* <TextInput label="Title" source="title" defaultValue="Hello, World!" /> */}
    </Filter>
);

const UserList = (props) => {
    return (
        <List {...props} filters={<ClientFilter />} bulkActionButtons={false} exporter={false}>
            <Datagrid>
                <TextField source="name" label="Client" sortable={false} />
                <TextField source="phone" label="Téléphone" sortable={false} />
                <EmailField source="email" label="Email"  sortable={false}/>
                <DateField source="connected_at" label="Dernière connexion"  sortable={false}/>
                <EditButton label="Mot de passe"/>
            </Datagrid>
        </List>
    );
}

export default UserList;