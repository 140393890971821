import * as React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import Impersonate from './configuration/Impersonate';
import Contact from './Contact';


const routes = [
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route exact path="/impersonate" render={() => <Impersonate />} />,
    <Route exact path="/contact" render={() => <Contact />} />,
];

export default routes;