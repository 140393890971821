import InvoiceList from './InvoiceList';
import InvoiceShow from './InvoiceShow';
import MoneyIcon from '@material-ui/icons/Money';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

export default {
  list: InvoiceList,
  show: InvoiceShow,
  icon: MoneyIcon,
  invoiceIcon: MoneyIcon,
  paymentIcon: LocalAtmIcon,
};